<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Surat Pesanan (PO) Apotek'">
          <template
            v-slot:body
            v-if="dataLoaded"
          >
            <Form
              :form="form"
              :route="'pharmacy-purchase-orders/' + form.id"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from "@/component/medicine/PurchaseOrderFormEdit.vue";
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
          date: "",
          po_no: "",
          vendor_id: "",
          vendor_name: "",
          items: "",
          discount: "",
          ppn: "",
          opening_sentence: "Sehubungan dengan surat penawaran harga (quotation) Nama Supplier, maka kami ingin memesan produk sebagai berikut :",
          closing_sentence: "Demikian pesanan ini kami sampaikan, Mohon kiranya dapat di proses dengan segera. Atas perhatian dan kerjasamanya kami ucapkan terimakasih",
      },
      // Other
      dataLoaded: false
    }
  },

  methods: {

    async get() {
      this.form = await module.get('pharmacy-purchase-orders/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/purchase-orders-pharmacy')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Surat Pesanan (PO) Apotek", route: "/purchase-orders-pharmacy" },
      { title: "Edit Surat Pesanan (PO) Apotek" },
    ])
    // Get Data
    this.get()
  },

}

</script>